import React, { useEffect } from "react";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
export default function Navbar() {
  const { t, i18n } = useTranslation();
  if (i18n.language === "ar") {
    document.body.style.direction = "rtl";
  } else if (i18n.language === "en") {
    document.body.style.direction = "ltr";
  }
  const location = useLocation(); // Hook to track the current route

  useEffect(() => {
    
    const navbarToggler = document.querySelector(".navbar-toggler");
    const collapse = document.getElementById("navbarSupportedContent");

    // Check if the menu is open (toggle the 'collapsed' class)
    if (collapse.classList.contains("show")) {
      navbarToggler.click(); // Manually toggle the menu to close it
    }
  }, [location]); 

  return (
    <div className="fixed-top">
      <div className="top-header bg-primary py-1">
        <div className="container">
          <div className=" d-flex justify-content-between align-items-center">
            <ul className="d-flex gap-2 m-0">
              <li>
                <div className="d-flex text-white  phone ">
                  <i class="fa-solid fa-phone mx-2 fs-5"></i>
                  {/* <span className='mx-2'>{t("Phone")}:</span> */}
                  <p className="m-0">24177846 - 26902534</p>
                </div>
              </li>
              <li>
                <div className="d-flex text-white email ">
                  <i class="fa-solid fa-square-envelope mx-2 fs-5"></i>
                  {/* <span className='mx-2'>{t("E-mail")}:</span> */}
                  <p className="m-0">Ciecontractors2050@gmail.com</p>
                </div>
              </li>
            </ul>
            <div class="footer-social-icons  m-0">
              <ul class="social-icons m-0">
                <li className="px-2">
                  <a href="" class="social-icon">
                    {" "}
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li className="px-2">
                  <a href="" class="social-icon">
                    {" "}
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li className="px-2">
                  <a href="" class="social-icon">
                    {" "}
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg nav-bg ">
        <div className="container">
        <a href="/home">
    <img class="logo" src={logo} alt="Logo"/>
</a>
          {/* <img className="logo" src={logo} alt="" /> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
         
              <ul
                className={
                  i18n.language == "ar"
                    ? "navbar-nav me-auto mb-2 mb-lg-0"
                    : "navbar-nav ms-auto mb-2 mb-lg-0"
                }
              >
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    aria-current="page"
                    activeClassName="home"
                   
                   
                    to={"/"}
                  >
                    {t("home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    aria-current="page"
                      activeClassName="home"
                    to={"/aboutus"}
                  >
                    {t("about")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " aria-current="page" 
                     activeClassName="home"
                   to={"/services"}
                   >
                    {t("services")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    aria-current="page"
                      activeClassName="home"
                    to={"/allproject"}
                  >
                    {t("projects")}
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link " 
                   aria-current="page"
                     activeClassName="home"
                   to={"/contactUS"}>
                    {t("contact")}
                  </Link>
                </li>
                <select
                  className={
                    i18n.language == "ar"
                      ? "form-select lang-menu-ltr"
                      : "form-select lang-menu"
                  }
                  onChange={(e) => {
                    window.localStorage.language = e.target.value;
                    i18n.changeLanguage(e.target.value);
                  }}
                  value={i18n.language}
                  name=""
                  id=""
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </ul>
            
          </div>
        </div>
      </nav>
    </div>
  );
}
