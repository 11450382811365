import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Sob1 from "../assets/images/sob1.jpeg";
import Sob2 from "../assets/images/sob2.jpeg";
import CoreStrengths from "../assets/images/felix-fuchs-P21wf6KAykw-unsplash 1.png";
import PageCover from "../component/PageCover";
export default function Services() {
  
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
      // Check if the URL contains an anchor (fragment identifier)
      if (location.hash) {
          const element = document.querySelector(location.hash);
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);
  return (
    <>
      <PageCover title={t("about")} />
      <div className="sec-two">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 my-5">
              <div className="sec-two-content">
                <h2>
                  {t("CieContactorshelpyoubuildyournextbeatifulplacetostay")}
                </h2>

                {/* <p>
              {t(
                "Wealwaysprovidingthebestsolutionforourclients,wedesignthebestbuildingtoreachyourhighestexpectation"
              )}
            </p> */}
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="sec-two-content" id="CoreStrengths">
                <div className="img-sec-two ">
                  <img src={logo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="sec-two-content">
                <div className="mt-5">
                  <img src={CoreStrengths} alt="" />
                </div>
              </div>
            </div>
            {/* <hr /> */}
            <div className="col-md-6">
              <h2 className="text-primary mb-3 text-bolder">
                {" "}
                {t("CoreStrengths")}{" "}
              </h2>
              <ul className="service-list">
                <li className="service-item">
                  <i className="fas fa-cogs"></i>
                  {t("point1")}
                </li>
                <li className="service-item">
                  <i className="fas fa-user-check"></i>
                  {t("point2")}
                </li>
                <li className="service-item">
                  <i className="fas fa-comments"></i>
                  {t("point3")}
                </li>
                <li className="service-item">
                  <i className="fas fa-network-wired"></i>
                  {t("point4")}
                </li>
                <li className="service-item">
                  <i className="fas fa-thumbs-up"></i>
                  {t("point5")}
                </li>
                <li className="service-item">
                  <i className="fas fa-clock"></i>
                  {t("point6")}
                </li>
                <li className="service-item">
                  <i className="fas fa-dollar-sign"></i>
                  {t("point7")}
                </li>
              </ul>

              {/* <ul className="">
            <li>{t("point1")}</li>
            <li>{t("point2")}</li>
            <li>{t("point3")}</li>
            <li>{t("point4")}</li>
            <li>{t("point5")}</li>
            <li>{t("point6")}</li>
            <li>{t("point7")}</li>
          </ul> */}
            </div>
          </div>
        </div>
      </div>
      <section className="aboutUs" id="visionmission">
        <div className="container">
          <div className="row my-5 ">
            <div className="col-lg-6">
              <h2 className="text-primary mb-3 text-bolder">
                {t("visionmission")}
              </h2>
              <p>{t("vissionmissinText")}</p>
              <h2 className="text-primary mb-3 text-bolder">
                {t("Scopeofbusiness")}
              </h2>
              <p>{t("ScopeofbusinessText")}</p>
              <ul>
                <li>{t("ScopeofbusinessItems1")}</li>
                <li>{t("ScopeofbusinessItems2")}</li>
                <li>{t("ScopeofbusinessItems3")}</li>
                <li>{t("ScopeofbusinessItems4")}</li>
                <li>{t("ScopeofbusinessItems5")}</li>
                <li>{t("ScopeofbusinessItems6")}</li>
                <li>{t("ScopeofbusinessItems7")}</li>
                <li>{t("ScopeofbusinessItems8")}</li>
                <li>{t("ScopeofbusinessItems9")}</li>
                <li>{t("ScopeofbusinessItems10")}</li>
              </ul>
              <p>
                {t("ScopeofbusinessText2")}
                <br />

                {t("ScopeofbusinessText3")}
              </p>
            </div>
            <div className="col-lg-6 ">
              <div className="sec-two-content image-box">
                <div className="mt-5 ">
                  <img className=" img1" src={Sob1} alt="" />
                  <img className=" img2" src={Sob2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary text-white py-5" id="servicesshall">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-white mb-3 text-bolder w-50">
                {t("Ourservicesshall")}
              </h2>
              <p>
              {t("Actasclient’s")}

                 </p>
            </div>
            <div className="col-md-6">
              <h4 className="text-white mb-3 text-bolder">
                {t("Schedulingcontrol")}
              </h4>
              <p>
              {t("SchedulingcontrolText")}

                
              </p>
              <h4 className="text-white mb-3 text-bolder">
                {t("Costcontrol")}
              </h4>
              <p>
              {t("CostcontrolText")}
                
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
