import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom'
import PageCover from '../component/PageCover';

export default function Services() {
    const {state}= useLocation()
    const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  return (
    <>
     <PageCover title={t("services")}/>
     <div className="sec-three" id="services">
    <div className="container">
      <div className="row mb-5 text-center">
        <div className="col-12">
          <div className="title">
            <h1>{t("services")}</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <section className="services-cards">
            <div className="service-card">
              <i className="fas fa-building"></i>
              <h3>{t("service1")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-calculator"></i>
              <h3>{t("service2")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-ruler-combined"></i>
              <h3>{t("service3")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-tools"></i>
              <h3>{t("service4")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-hard-hat"></i>
              <h3>{t("service5")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-handshake"></i>
              <h3>{t("service6")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-fire-extinguisher"></i>
              <h3>{t("service7")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-road"></i>
              <h3>{t("service8")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-seedling"></i>
              <h3>{t("service9")}</h3>
            </div>
            <div className="service-card">
              <i className="fas fa-building"></i>
              <h3>{t("service10")}</h3>
            </div>
          </section>
        </div>
      </div>

    
    </div>
  </div>
    </>
    
  )
}
