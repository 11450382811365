import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
// import 'swiper/css/navigation';
import * as Yup from "yup";
import ApiCaller from "../api/ApiCaller";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d352.59839730521685!2d31.338044143613175!3d30.071423976048738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e13a27d570f%3A0xaa3efa441fe3227b!2z2YLZhtiv2YrZhCDZhdi12LEgS2FuZGlsIEVneXB0!5e0!3m2!1sen!2seg!4v1686724130918!5m2!1sen!2seg";

  const schema = Yup.object({
    name: Yup.string().required("Please Enter  name"),
    email: Yup.string().email("invalid Email").required("Please Enter  email"),
    message: Yup.string().required("Please Enter  message"),
    PhoneNumber: Yup.string().required("Please Enter  Phone Number"),
  });
  return (
    <>
      <div className="sec-footer">
        <div className=" shadow-sm">
          <footer className="section-footer border-top">
            <div className="container mt-5">
              <section className="footer-top padding-y">
                <div className="row">
                  <aside className="col-md-4">
                    <article className="mr-3 d-flex align-items-center">
                      {" "}
                      <img src={logo} className="logo-footer" />
                      <p className="ms-3 my-3 descriptions">
                      {t("AboutFooter")}

                      </p>
                    </article>
                  </aside>
                  <div className="col-6  col-sm-3 col-md-2">
                    <h6 className="title">{t("about")}</h6>
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                       <Link aria-current="page" to={"/aboutus#CoreStrengths"}>
                        {t("CoreStrengths")}
                        </Link>
                      </li>
                      <li>
                        {" "}
                       <Link aria-current="page" to={"/aboutus#visionmission"}>
                        {t("visionmission")}
                        </Link>
                      </li>
                      <li>
                        {" "}
                       <Link aria-current="page" to={"/aboutus#visionmission"}>
                        {t("Scopeofbusiness")}
                        </Link>
                      </li>
                      <li>
                        {" "}
                       <Link aria-current="page" to={"/aboutus#servicesshall"}>
                        {t("servicesshall")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-sm-3 col-md-2">
                    <h6 className="title">{t("UsefulLinks")}</h6>
                    <ul className="list-unstyled">
                      <li>
                        <Link aria-current="page" to={"/aboutus"}>
                          {t("about")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=" "
                          aria-current="page"
                          to={"/services"}
                        >
                          {t("services")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=""
                          aria-current="page"
                          to={"/allproject"}
                        >
                          {t("projects")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className=" "
                          aria-current="page"
                          to={"/contactUS"}
                        >
                          {t("contact")}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-5 col-md-4">
                    <h6 className="title">{t("contact")}</h6>
                    <div className="company-data">
                      <div className="address my-2">
                        <i className="fa-solid fa-map-location mx-2 fs-5"></i>
                        {/* <span className='mx-2'>{t("Address")}:</span> */}
                        <p>
                          {t(
                            "31 Rabaa Investment Buildings - in front of the Workers' University - Nasr City"
                          )}
                        </p>
                      </div>
                      <div className="phone my-2">
                        <i className="fa-solid fa-phone mx-2 fs-5"></i>
                        {/* <span className='mx-2'>{t("Phone")}:</span> */}
                        <p>24177846 - 26902534</p>
                      </div>
                      <div className="email my-2">
                        <i className="fa-solid fa-square-envelope mx-2 fs-5"></i>
                        {/* <span className='mx-2'>{t("E-mail")}:</span> */}
                        <p>Ciecontractors2050@gmail.com</p>
                      </div>
                    </div>
                  </div>
                  {/* <aside className="col-sm-2 col-md-2">
                    <h6 className="title">Our app</h6>{" "}
                    <a href="#" className="d-block mb-2" data-abc="true">
                      <img
                        className="img-responsive"
                        src="https://i.imgur.com/nkZP7fe.png"
                        height="40"
                      />
                    </a>{" "}
                    <a href="#" className="d-block mb-2" data-abc="true">
                      <img
                        className="img-responsive"
                        src="https://i.imgur.com/47q2YGt.png"
                        height="40"
                        width="123"
                      />
                    </a>
                  </aside> */}
                </div>
              </section>
              <div className="container-fluid">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top">
                  <div className="col-md-4 d-flex align-items-center">
                    <a
                      href="/"
                      className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
                    ></a>
                    <span className="mb-3 mb-md-0 text-muted">
                      © 2024 Cie Contactors
                    </span>
                  </div>

                  <ul className="nav col-md-4 justify-content-center list-unstyled d-flex">
                    <li className="ms-3">
                      <a className="text-muted" href="#">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li className="ms-3">
                      <a className="text-muted" href="#">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li className="ms-3">
                      <a className="text-muted" href="#">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                  </ul>
                </footer>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
